<template>
  <div>
    <div class="container">
      <div class="form-container">
        <form @submit.prevent="resetPassword">
          <h3 class="color-primary text-center">เปลี่ยนรหัสผ่าน</h3>
          <label class="color-primary">รหัสผ่านใหม่</label>
          <div class="position-relative">
            <input
              class="w-100"
              :type="passwordDisplay"
              v-model="password"
              minlength="8"
              placeholder="รหัสผ่านใหม่"
              required
            />
            <img
              class="password-icon"
              src="../assets/images/showpassword.svg"
              alt=""
              @click="togglePasswordDisplay"
            />
          </div>
          <label class="color-primary">ยืนยันรหัสผ่านใหม่</label>
          <div class="position-relative">
            <input
              class="w-100"
              :type="confirmDisplay"
              v-model="confirmPassword"
              minlength="8"
              placeholder="ยืนยันรหัสผ่านใหม่"
              required
            />
            <img
              class="password-icon"
              src="../assets/images/showpassword.svg"
              alt=""
              @click="toggleConfirmDisplay"
            />
          </div>
          <button class="primary w-100">
            <p class="text-big">เปลี่ยนรหัสผ่าน</p>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../api/faAPI';;
import baseUrl from "../util/backend";
export default {
  data() {
    return {
      token: "",
      passwordDisplay: "password",
      confirmDisplay: "password",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    togglePasswordDisplay() {
      if (this.passwordDisplay === "password") {
        this.passwordDisplay = "text";
      } else {
        this.passwordDisplay = "password";
      }
    },
    toggleConfirmDisplay() {
      if (this.confirmDisplay === "password") {
        this.confirmDisplay = "text";
      } else {
        this.confirmDisplay = "password";
      }
    },
    async resetPassword() {
      if (this.password !== this.confirmPassword) {
        alert("รหัสผ่านไม่ตรงกัน");
        return;
      }
      this.$store.dispatch("setLoading", true);
      try {
        const res = await axios.post(
          `${baseUrl}/checkResetPasswordToken`,
          {
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        alert("เปลี่ยนรหัสผ่านสำเร็จ");
        this.$router.replace({ name: "Home" });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.$store.dispatch("setLoading", false);
    },
  },
  created() {
    this.token = this.$route.query.token;
  },
};
</script>

<style scoped>
.form-container {
  max-width: 370px;
  margin: 110px auto;
}

.form-container input {
  border: 1px solid #0430ad;
  outline: none;
  padding: 10px;
  margin-bottom: 10px;
}

button.primary {
  color: #fff;
  background-color: #0430ad;
  padding: 10px;
  margin: 10px 0;
}

.password-icon {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 5px);
  right: 10px;
  transform: translateY(-50%);
}
</style>
